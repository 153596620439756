@import '@/common/styles/theme.less';
// modal 公用样式
.ant-modal-root {
    .ant-modal {
        // top: 20%;
        top: 15%;
        .ant-modal-header {
            font-size: 20px;
            color: @color-dark-grey;
            line-height: 28px;
            padding: 17px 24px;
            .ant-modal-title {
                line-height: 28px;
                font-size: 20px;
                font-family: DiDiSans-Medium;
                font-weight: 500;
            }
        }
        .ant-modal-content {
            border-radius: 4px;
            .ant-modal-close-x {
                .anticon {
                    width: 20px;
                    height: 20px;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
           
            .ant-modal-body {
                max-height: 60vh;
                overflow-y: auto;
            }
        }
        .ant-modal-footer {
            padding: 16px 24px 24px;
            button, .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
                margin-left: 20px;
            }
        }
    }
  }
  

@primary-color: #FF8852;@link-color: #FF8852;