/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
@import './theme.less';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    vertical-align: baseline;
}

strong {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

body {
    overflow: hidden;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
    display: block;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* custom */
a {
    -webkit-backface-visibility: hidden;
    text-decoration: none;
}

li {
    list-style: none;
}

:focus {
    outline: none !important;
}

html,
#root {
    height: 100%;
    width: 100%;
}

body {
    min-height: 100%;
    width: 100%;
    font-family: DiDiSans-Regular, -apple-system, BlinkMacSystemFont, sans-serif, Open Sans, Segoe UI, Roboto, Oxygen, Cantarell, Helvetica Neue, Icons16 !important;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    * {
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
    }

    /*   background-color: #181818; */
}

/* 定义字体 */
@font-face {
    font-family: 'DiDiSans-Regular';
    src: url('~@/assets/font/DiDiSans-Regular.ttf') format('truetype');
}
 
@font-face {
    font-family: 'DiDiSans-Medium';
    src: url('~@/assets/font/DiDiSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'DiDiSans-Bold';
    src: url('~@/assets/font/DiDiSans-Bold.ttf') format('truetype');
}

@font-face {    
    font-family: 'DiDiSans-Pro-Bold';
    src: url('~@/assets/font/DiDiSans-Pro-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'DiDiSans-Pro-Medium';
    src: url('~@/assets/font/DiDiSans-Pro-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'DiDiSans-Pro-Regular';
    src: url('~@/assets/font/DiDiSans-Pro-Regular.ttf') format('truetype');
}
 

/* 测试 使用字体 */
.page-title {
    font-family: DiDiSans-Medium;
}
@primary-color: #FF8852;@link-color: #FF8852;