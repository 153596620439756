@import './theme.less';

body {
  margin: 0;
  font-family:  DiDiSans-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

.ant-layout {
  height: 100vh;
  // font-family: DiDiSans-Regular;
  .ant-layout-content {
    height: calc(100vh - 64px);
    overflow: scroll;
    .page-title {
      font-family: DiDiSans-Medium;
      font-weight: 500;
      font-size: 24px;
      color: @color-dark-grey;
      line-height: 32px;
      margin-bottom: 16px;
    }
    .page-header-text {
      .page-tip {
        margin-top: -3px;
        margin-bottom: 16px;
        font-family: DiDiSans-Pro-Regular;
        font-size: 16px;
        color: #595C66;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;

      }
    }
    .site-layout-background {
      // padding: 0;
    }
  }
  .ant-layout-header {
    padding: 0 24px;
  }

  // select 样式
  .ant-select,
  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 32px;
        border: 1px solid @color-border-line;
        border-radius: 4px;
      }
    }

  }
  .ant-layout-content {
    .ant-picker.ant-picker-range, .ant-input-affix-wrapper, .ant-input {
      // border-radius: 4px;
    }
  }
}

// select dropdown 样式
.ant-select-dropdown {
  padding: 20px 16px;
  .rc-virtual-list {
    .rc-virtual-list-holder-inner {

    }
  }
}

// 下拉选择框 部分样式
// .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft.ant-select-dropdown-hidden
.ant-select-dropdown {
  padding: 12px;
  width: auto !important;

  .rc-virtual-list {

      .rc-virtual-list-holder {

          .ant-select-item {
              padding: 0;
              line-height: 30px;
              background-color: transparent;
              border-radius: 4px;
              // margin-bottom: 4px;

              .ant-select-item-option-content {
                  margin: 2px 0;
                  padding: 0 8px;
                  border-radius: 4px;
              }

              &.ant-select-item-option-active {
                  background-color: transparent;

                  .ant-select-item-option-content {
                      // background: @color-white;
                      color: @color-light-text;
                  }

                  &:not(.ant-select-item-option-disabled) {
                      // background-color: transparent;
                  }
              }

              &.ant-select-item-option-selected {
                  background: @color-orange-shadow;
                  .ant-select-item-option-content {
                      color: @color-light-text;
                  }

                  .ant-select-item-option-state {
                      padding-right: 4px;
                  }

                  &:not(.ant-select-item-option-disabled) {
                      // background: transparent;
                  }
              }
          }

      }
  }
}

// dropdown menu 全局样式
.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical {

}
.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  min-width: 200px;
  padding: 20px 16px 12px 16px;
  .ant-dropdown-menu-item-selected {
    .msg-sort {
      color: @color-dark-orange-s;
    }
  }
  .ant-dropdown-menu-item {
    &:hover {
      .msg-sort {
        color: @color-dark-orange-s;
      }
    }
    line-height: 32px;
    padding: 0 8px;
    margin-bottom: 8px;
  }
  .ant-dropdown-menu-item-active {
    background: @color-white;
    color: @color-light-text;
    border-radius: 4px;
  }
  .ant-dropdown-menu-item-selected {
    background: @color-orange-shadow;
    border-radius: 4px;
    color: @color-black;
  }
  .ant-dropdown-menu-item.curr-item {
    background: @color-orange-shadow;
    border-radius: 4px;
    color: @color-light-text;
  }
}

// 全局提示 样式修改 success & error & warning
.ant-message {
  top: 12px;

  .ant-message-notice {
    .ant-message-notice-content {
      padding: 0;
      min-width: 315px;
      background-color: transparent;

      .ant-message-custom-content {
        color: @color-white;
        padding: 4px 12px;
        border-radius: 4px;
        text-align: left;

        .anticon {
          display: none;
        }

        &.ant-message-success {
          color: @color-white;
          background: @color-light-deepgreen;
        }

        &.ant-message-error {
          color: @color-white;
          background: @color-common-red;
        }

        &.ant-message-warning {
          background: @color-common-greenyellow;
          color: @color-dark-grey;
        }
      }
    }
  }
}

// spin loading 样式修改
.ant-spin-nested-loading {
  .ant-spin.ant-spin-spinning {
    .anticon.ant-spin-dot {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}


// button  primary & danger css
html  {
  .ant-btn{
    font-size: 14px;
    color:#666;
    text-align: center;
    line-height: 20px;
    padding: 5px 16px;
    border-radius: 4px ;
    font-family: DiDiSans-Medium;
    font-weight: 500;
  }
  .ant-btn-primary {
    background-image: linear-gradient(135deg, rgba(255,98,0,0.65) 0%, #FF6200 100%);
    background-color: transparent;
    padding: 5px 16px;
    color: @color-white;
    border: 1px solid #FF6200;
    border-radius: 4px ;
    &:hover {
      background-image: linear-gradient(135deg, #F8A872 0%, #F6863C 100%);
    }
    &:active {
      background-image: linear-gradient(135deg, #D77C45 0%, #D76017 100%);
    }
    &:disabled {
      background-image: linear-gradient(135deg,#FBD7BE  0%,#FACBAB  100%) !important;
      border: 1px solid @color-orange-disable;
      color: @color-white !important;
    }
  }
  .ant-btn-dangerous{
    color: @color-white;
    background-image: linear-gradient(135deg,#D6797E  0%,#C7464C 100%) !important;
    border: 1px solid #C7464C;
    &:hover{
    color: @color-white;
    border: 1px solid #C7464C;
    background-image: linear-gradient(135deg,#D6797E  0%,#C7464C 100%) !important;
    }
  }
}

// 四个status的样式 
.status {
  color: @color-white;
  padding:4px 14px ;
  height: 24px;
  display: inline-block;
  border-radius: 12px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  background: rgba(255, 255, 255, 0);
  &.success {
    border: 1px solid #42983D;
    background-image: linear-gradient(135deg, rgba(66, 152, 61, 0.65) 0%, #42983D 100%);
  }

  &.pending {
    border: 1px solid #2DA9B5;
    background-image: linear-gradient(135deg, rgba(45, 169, 181, 0.65) 0%, #2DA9B5 100%);
  }

  &.waiting {
    border: 1px solid #2567D5;
    background-image: linear-gradient(135deg, rgba(37, 103, 213, 0.65) 0%, #2567D5 100%);
  }

  &.warning {
    border: 1px solid  #FFB830;
    color:  @color-dark-grey;
    background-image: linear-gradient(135deg, rgba(255, 184, 48, 0.65) 0%, #FFB830 100%);
  }

  &.suspended {
    border: 1px solid #BDBDBD;
    color:  @color-dark-grey;
    background-image: linear-gradient(135deg, rgba(189, 189, 189, 0.65) 0%, #BDBDBD 100%);
  }

  &.fail {
    border: 1px solid #C7464C;
    background-image: linear-gradient(135deg, rgba(199, 70, 76, 0.65) 0%, #c7464c 100%);
  }
}
//表格样式
.ant-table {
  border-radius: 8px ;
  overflow: hidden;
  border: 1px solid @color-pewter-b ;
  // border-bottom: none;
}
.ant-table-thead{
  .ant-table-cell{
    font-size: 14px;
    color: @color-chart-text;
    line-height: 20px;
    font-weight: 400;
    background:@color-white;
    padding: 14px 16px 12px 16px;
    ::after {
      
    }
  }
  tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-cell-fix-right {
    padding: 14px 24px 12px 24px;
  }
}
.ant-table-tbody {
  .ant-table-cell {
    padding: 14px 16px 12px 16px;
    &.ant-table-cell-fix-right {
      padding: 14px 24px 12px 24px;
    }
  } 
}

// 分页器样式
.ant-pagination.ant-table-pagination ,
.ant-pagination.pagination{
  width: 100%;
  background: @color-white;
  display: flex;
  justify-content: flex-end;
  padding: 20px 16px 20px 16px;
  border: 1px solid @color-pewter-b;
  border-radius: 0 0 8px 8px;
  margin:0;
  margin-top: -4px;
  position: relative;
  z-index: 9;
  .ant-pagination-prev , .ant-pagination-next{
      width: 32px;
      height: 32px;
      background: @color-white;
      border: 1px solid #DEE0E2;
      border-radius: 4px;
      margin: 0;
      &.ant-pagination-disabled{
        display: none;
      }
  }
  .ant-pagination-item  {
    width: 36px;
    height: 32px;
    border-radius: 4px;
    border: none;
    margin-right: 0;
    background: transparent;
    &.ant-pagination-item-1.ant-pagination-item-disabled{
      background: @color-orange-disable;
      color: @color-orange-f
    }
    &:hover{
      color: @color-orange-f;
    }
    &.ant-pagination-item-active{
      background: @color-orange-disable;
      color: @color-orange-f;
    }
  }

  .ant-pagination-item-ellipsis {
    width: 36px;
    height: 32px;
  }
  .ant-pagination-options{
    order: -1;
    margin-right: 20px;
  }
}

// 后续删除
//表格样式
.ant-table-wrapper{
  .ant-table {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }
  .ant-table-content {
    min-height: calc(100vh - 400px);;
  }
  .ant-table-empty {
    .ant-table-tbody .ant-table-cell {
      border-bottom: none;
    }
  }
  .ant-table-thead{
    .ant-table-cell{
      background:@color-white;
      padding: 14px 16px 12px 16px;
      ::after {
        
      }
    }
    tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      display: none;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 14px 16px 12px 16px;
    }
  }
}




// overwrite menus commons styles
body {
  .ant-layout-sider,
  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    line-height: 20px;
    background-color: @color-dunk-blue;
    overflow: scroll;
  }
  .ant-menu-inline.ant-menu-root ,
  .ant-menu-dark.ant-menu-dark{
    .ant-menu-item-selected {

      &.ant-menu-item-only-child {
        background-color: transparent;
      }
    }
    .ant-menu-inline.ant-menu-sub {
      background-color: transparent;

      .ant-menu-submenu-title {
        // width: 216px;
        // height: 48px;
        background-color: @color-menu-title;
        border-radius: 8px;
      }
    }
    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected,  .ant-menu-submenu-active, .ant-menu-submenu-open {
        // background-color: transparent;

        // background-color: @color-menu-title;
        // border-radius: 8px;

        &.ant-menu-item-only-child {
          background-color: transparent;
        }
        div.ant-menu-submenu-title {
          // width: 216px;
          // height: 48px;
          background-color: @color-menu-title;
          border-radius: 8px;
        }
        .ant-menu-title-content {
          font-size: 14px;
          color: @color-white;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }
  }
  .ant-menu-submenu-arrow {
    position: absolute;
    right: 10px;
  }
  .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
    // todo
      background-color: @color-common-blue;
      border-radius: 4px;
  }
  .ant-tooltip-arrow {
      display: none;
  }
}

// 筛选项共用样式
.filter-container {
    background: @color-white;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    position: relative;
    
    .filter-item {
        display: flex;
        flex-direction: column;
        margin-right: 8px;
        .filter-title {
            margin-bottom: 8px;
        }
        .filter-content {
            width: 274px;
            .ant-select, .ant-picker {
                width: 100%;
            }
        }
    }

    .button-group {
        position: absolute;
        bottom: 16px;
        right: 16px;
        .ant-btn {
            margin-left: 8px;
        }
    }
}
.ant-form-item-control-input-content {
  .ant-input-affix-wrapper {
    border-radius: 4px;
  }
}


// 通用 表单校验 报错 提示样式
.ant-form {
  .ant-row.ant-form-item-has-error {
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input-affix-wrapper {
            border: 1px solid #C7464C;
            box-shadow: 0 0 0 2px rgba(199,70,76,0.20);
            border-radius: 4px;
          }
        }
      }
      .ant-form-item-explain.ant-form-item-explain-connected {
        background: #C7464C;
        border-radius: 4px;
        padding: 6px 16px;
        margin-top: 4px;
        display: inline-block;
        .ant-form-item-explain-error {
          line-height: 20px;
          color: white;
        }
      }
    }
  }

}

// popover 共用样式
.ant-popover-inner-content {
  padding: 20px 0 0 0;

  .confirm-content {
      font-size: 14px;

      .confirm-title {
          font-size: 14px;
          color: @color-dark-grey;
          margin-bottom: 8px;
          padding: 0 16px;
      }

      .confirm-tip {
          color: @color-grey;
          margin-bottom: 20px;
          padding: 0 16px;
      }

      .confirm-footer {
          border-top: 1px solid @color-border-top;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 8px 16px 16px 16px;

          button {
              margin-left: 8px;
          }
      }
  }
}

@primary-color: #FF8852;@link-color: #FF8852;